/* --- Notes --- */



/* --- Start SCSS --- */

#casestudy-footer {
  background-color: $white;
  padding-top: 40px;
  padding-bottom: 20px;

  h2, h4 { color: $bronze; }


  &.blue-bg {
    background-color: $blue;
    h2, h4 { color: $white; }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  #casestudy-footer {
    padding-top: 80px;
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #casestudy-footer {
    
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  #casestudy-footer {

  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  #casestudy-footer {
    padding-top: 100px;
  }
}  