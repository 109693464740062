
/* --- Notes --- */


/* -- Typography -- */

@mixin caps-heading($color:"") {
  @include font($body,11,13,700,$color);
  text-transform: uppercase;
  letter-spacing: 0.7px;
}

@mixin p-big {
  @include font($body,14,17,400);
}

@mixin p {
  @include font($body,13,16,400);
}

@mixin p($font:"", $font-weight:"", $color:"") {
  @if $font == $body { 
    @include font($body,13,16,$font-weight,$color);
  }
  @if $font == $heading { 
    @include font($heading,14,17,$font-weight,$color);
  }
}

@mixin p-small {
  @include font($body,11,13,400);
}

@mixin p-v-small {
  @include font($body,9,11,400);
}