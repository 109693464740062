/* --- Notes --- */



/* --- Start SCSS --- */

.secondary-media {

  & + .secondary-media { margin-top: 40px; }

  .title {
    @include caps-heading($bronze);
    border-top: 1px solid $bronze;
    padding-top: 2px;
    margin-bottom: 6px;
  }
  
  .active-media {
    animation: 1500ms infinite alternate img-loading;
  }
  
  .thumbnail-grid {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 5px;
    margin-top: 5px;
    user-select: none;

    .img-wrapper {
      background-color: $blue;
      cursor: pointer;

      img { transition: all $iosEase; }

      &.is-video {
        &:before{
          content: '';
          position: absolute;
          bottom: 10px;
          left: 11px;
          z-index: 1;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 0 5px 9px;
          border-color: transparent transparent transparent $white;
        }
      }

      &.active {
        &:after{
          content: '';
          position: absolute;
          top: 10px;
          left: 10px;
          width: 10px;
          height: 10px;
          background-color: $white;
          border-radius: 10px;
        }
      }

      @media (-moz-touch-enabled: 0),(hover: hover) {
        &:hover {
          img { opacity: 0.75; }
        }
      }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {

  .secondary-media {

    & + .secondary-media { margin-top: 80px; }


    .thumbnail-grid {
      grid-template-columns: repeat(7,1fr);
      grid-gap: 10px;
      margin-top: 10px;
    }
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .secondary-media {
    .thumbnail-grid {
      grid-template-columns: repeat(8,1fr);
    }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .secondary-media {
    
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .secondary-media {
    .thumbnail-grid {
      grid-template-columns: repeat(9,1fr);
    }
  }
}  