/* --- Notes --- */



/* --- Start SCSS --- */

#capability {
  // padding-top: 30px;
  z-index: 1;
  position: relative;

  .hero {
    position: sticky;
    top: $xs-menu;
    z-index: -1;
    overflow: hidden;
  }

  .content-wrapper > .grid {
    padding-top: 60px;
    grid-row-gap: 30px;
  }



  .intro {
    h1 {
      @include caps-heading($blue);
      margin-bottom: 15px;
    }

    h2 {
      @include font($heading,35,33,600,$bronze);
      margin-bottom: 15px;
    }

    p {
      @include p($heading,400,$blue);

      & + p {
        margin-top: 12px;
      }
    }
  }

  .secondary {
    h2 {
      @include font($heading,25,26,600,$bronze);
      padding-top: 15px;
      padding-bottom: 20px;
    }

    .highlights {
      padding-top: 30px;
      margin-bottom: -15px;

      li:nth-of-type(odd) {
        @include font($heading,17,20,600,$bronze);
        border-top: 1px solid $bronze;
        padding-top: 5px;
        padding-bottom: 2px;

        & + li { margin-bottom: 15px; }
      }

      li:nth-of-type(even) {
        @include p($heading,400,$blue);
      }
    }
  }
  
  .project-teaser {
    color: $black;
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  #capability {
    // padding-top: 70px;

    .hero {
      top: $sm-menu;
    }

    .content-wrapper > .grid {
      grid-column-gap: 25px;
      grid-row-gap: 65px;
    }

    .intro {
      padding: 0 40px 16px 0;
    }

    .secondary {
      padding-top: 60px;

      h2 {
        padding: 0 50px 25px 0;
      }

      .highlights {
        padding-top: 0;
      }
    }
    
    .project-teaser {
      &.top { margin-bottom: auto; }
      &.bottom { margin-top: auto; }

      &.v-watch {
        opacity: 0;
        position: relative;
        bottom: -50px;

        &:nth-of-type(2) { transition-delay: 250ms !important; }
        &:nth-of-type(3) { transition-delay: 500ms !important; }

        &.visible {
          bottom: 0;
          opacity: 1;
          transition: 1000ms ease-in-out;
        }
      }
    }
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #capability {
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  #capability {
    
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  #capability {

    > .grid {
      grid-column-gap: 60px;
    }

    .intro {
      padding-right: 60px;

      h2 {
        @include update-font(40,39);
        margin-bottom: 17px;
      }

      p {
        @include update-font(15,18);
        & + p { margin-top: 12px; }
      }
    }

    .secondary {
      padding-left: 40px;

      h2 {
        @include update-font(30,31);
        padding-right: 40px;
      }

      .highlights {
        li:nth-of-type(odd) {
          @include update-font(18,21);
          padding-bottom: 4px;

          & + li { margin-bottom: 18px; }
        }

        li:nth-of-type(even) {
          @include update-font(15,18);
        }
      }
    }
  }
}  