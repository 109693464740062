/* --- Notes --- */



/* --- Start SCSS --- */

.casestudy-grid {
  padding-left: 10px;
  border-left: 1px solid $bronze;

  .projects-wrapper {
    position: relative;
    padding-right: 10px;
    border-right: 1px solid $bronze;
    grid-row-gap: 20px;
    grid-column-gap: 20px;;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: calc(50% - 5px);
      width: 1px;
      height: 100%;
      background-color: $bronze;
    }
  }
  
  h2 {
    text-transform: capitalize;
    margin-bottom: 35px;
    @include font($heading,18,20,400,$white);
  }

  h4 {
    @include caps-heading;
    color: $bronze;
    margin-bottom: 30px;
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .casestudy-grid {
    h2 {
      @include update-font(25,27);
    }
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .casestudy-grid {
    .projects-wrapper.md-col-3 {
      &:before { left: calc(33.33333% - 7.5px); }
      &:after { left: calc(66.66666% - 4.5px); }
    }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .casestudy-grid {

  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .casestudy-grid {

  }
}  