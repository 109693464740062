
/* --- Notes --- */


/* --- Helper --- */

@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

@mixin circle ($size,$color) {
  border-radius: 50%;
  width: $size;
  height: $size; 
  background-color: $color;
  display: inline-block;
}

@mixin absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin centerXY($transform:null) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) $transform;
}

@mixin centerX($transform:null) {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) $transform;
}

@mixin centerY($transform:null) {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) $transform;
}

@mixin graident($x,$y,$deg) {
  background-image: linear-gradient($deg, $x 0%, $y 100%);
}

@mixin font($font-family:"", $font-size:"", $line-height:"", $font-weight:"", $color:"") {
  @if $font-family != "" { font-family: $font-family; }
  @if $font-size != "" { font-size: $font-size + px; }
  @if $line-height != "" { line-height: $line-height + px; }
  @if $font-weight != "" { font-weight: $font-weight; }
  @if $color != "" { color: $color; }
}

@mixin update-font($font-size:"", $line-height:"", $font-weight:"", $important:false) {
  @if $important == true {
    @if $font-size != "" { font-size: $font-size + px !important; }
    @if $line-height != "" { line-height: $line-height + px !important; }
    @if $font-weight != "" { font-weight: $font-weight !important; }
  } @else {
    @if $font-size != "" { font-size: $font-size + px; }
    @if $line-height != "" { line-height: $line-height + px; }
    @if $font-weight != "" { font-weight: $font-weight; }
  }
}

// @include placeholder { color: $grey; }
@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}


// @include ellipsis(200px);
@mixin ellipsis($max-width) {
  max-width: $max-width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


@mixin gpu() {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
}