/* --- Notes --- */

$toggle-size : 20px;

/* --- Start SCSS --- */


.accordion-item {

  .accordion-title {
    user-select: none;
    cursor: pointer;
    position: relative;
    padding: 10px 0;
    display: grid;
    grid-template-columns: 1fr $toggle-size;
    grid-gap: $toggle-size;

    .toggle {
      width: $toggle-size;
      height: $toggle-size;
      position: relative;

      &:before,
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        display: block;
        background-color: $black;
        @include centerXY;
      }

      &:before { 
        width: 1px;
        height: 100%;
      }

      &.active {
        &:before { 
          display: none;
        }
      }

      @media (hover) {
        &:hover {
          color: hotpink;
        }

        &:hover:before,
        &:hover:after {
          background-color: hotpink;
        }
      }
    }
  }

  .accordion-content {
    width: 100%;
    position: relative;
    height: 0;
    opacity: 0;
    transition: 500ms ease-in-out;
    overflow: hidden;

    &.active {
      opacity: 1;
    }

    .accordion-inner {
      padding-bottom: 40px;
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .accordion-item {
  	
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .accordion-item {
  	
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .accordion-item {
  	
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .accordion-item {
  	
  }
}  