
/* --- Notes --- */


/* --- Fonts --- */


@font-face {
  font-family: 'Neue Haas Grotesk';
  src: url('/assets/fonts/NeueHaasGrotText-55Roman-Web.woff2') format('woff2'),
       url('/assets/fonts/NeueHaasGrotText-55Roman-Web.woff') format('woff');
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk';
  src: url('/assets/fonts/NeueHaasGrotText-65Medium-Web.woff2') format('woff2'),
       url('/assets/fonts/NeueHaasGrotText-65Medium-Web.woff') format('woff');
  font-weight:  600;
  font-style:   normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk';
  src: url('/assets/fonts/NeueHaasGrotText-75Bold-Web.woff2') format('woff2'),
       url('/assets/fonts/NeueHaasGrotText-75Bold-Web.woff') format('woff');
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Farnham";
  src: url("/assets/fonts/Farnham-Text.woff2") format("woff2"),
       url("/assets/fonts/Farnham-Text.woff") format("woff");
  font-weight: 400; 
  font-style: normal; 
  font-display: auto; 
}

@font-face {
  font-family: "Farnham";
  src: url("/assets/fonts/Farnham-Semi-Bold.woff2") format("woff2"),
       url("/assets/fonts/Farnham-Semi-Bold.woff") format("woff");
  font-weight: 600; 
  font-style: normal; 
  font-display: auto; 
}

   
// @font-face {
//   font-family: "Farnham";
//   font-weight: 400; 
//   font-style: normal; 
//   font-display: auto; 
//   src: url("//cloud.typenetwork.com/projectLicenseWeb/4587/fontfile/eot/?9d0bc8606eac07a6dd7672f1fd56ffe6c756db9b");
//   src: url("//cloud.typenetwork.com/projectLicenseWeb/4587/fontfile/eot/?9d0bc8606eac07a6dd7672f1fd56ffe6c756db9b#iefix") format("embedded-opentype"),
//        url("//cloud.typenetwork.com/projectLicenseWeb/4587/fontfile/woff2/?9d0bc8606eac07a6dd7672f1fd56ffe6c756db9b") format("woff2"),
//        url("//cloud.typenetwork.com/projectLicenseWeb/4587/fontfile/woff/?9d0bc8606eac07a6dd7672f1fd56ffe6c756db9b") format("woff");
// }

// @font-face {
//   font-family: "Farnham";
//   font-weight: 600; 
//   font-style: normal; 
//   font-display: auto; 
//   src: url("//cloud.typenetwork.com/projectLicenseWeb/4586/fontfile/eot/?9d0bc8606eac07a6dd7672f1fd56ffe6c756db9b");
//   src: url("//cloud.typenetwork.com/projectLicenseWeb/4586/fontfile/eot/?9d0bc8606eac07a6dd7672f1fd56ffe6c756db9b#iefix") format("embedded-opentype"),
//        url("//cloud.typenetwork.com/projectLicenseWeb/4586/fontfile/woff2/?9d0bc8606eac07a6dd7672f1fd56ffe6c756db9b") format("woff2"),
//        url("//cloud.typenetwork.com/projectLicenseWeb/4586/fontfile/woff/?9d0bc8606eac07a6dd7672f1fd56ffe6c756db9b") format("woff");
// }