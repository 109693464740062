/* --- Notes --- */


@keyframes ken-burns-bottom-right{to{transform: scale3d(1.25,1.25,1.25) translate3d(-5%,-3.5%,0);}}
@keyframes ken-burns-top-right{to{transform: scale3d(1.25,1.25,1.25) translate3d(-5%,3.5%,0);}}
@keyframes ken-burns-top-left{to{transform: scale3d(1.25,1.25,1.25) translate3d(5%,3.5%,0);}}
@keyframes ken-burns-bottom-left{to{transform: scale3d(1.25,1.25,1.25) translate3d(5%,-3.5%,0);}}
@keyframes ken-burns-middle-left{to{transform: scale3d(1.25,1.25,1.25) translate3d(5%,0,0);}}
@keyframes ken-burns-middle-right{to{transform: scale3d(1.25,1.25,1.25) translate3d(-5%,0,0);}}
@keyframes ken-burns-top-middle{to{transform: scale3d(1.25,1.25,1.25) translate3d(0,5%,0);}}
@keyframes ken-burns-bottom-middle{to{transform: scale3d(1.25,1.25,1.25) translate3d(0,-5%,0);}}
@keyframes ken-burns-center{to{transform: scale3d(1.25,1.25,1.25);}}


/* --- Start SCSS --- */

.ken-burns {

  img { 
    animation-direction: normal, normal; 
    animation-timing-function: linear, ease; 
  }

  .preload { visibility: hidden; }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {

  .ken-burns {

   
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .ken-burns {
    
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .ken-burns {
    
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .ken-burns {
    
  }
}  