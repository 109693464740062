
/* --- Notes --- */


/* --- Variables --- */

$white 				: rgb(255,255,255);
$grey-1 			: rgb(221,221,221);
$grey-2 			: rgb(213,213,213);
$grey-3 			: rgb(202,202,202);
$grey-4 			: rgb(189,189,189);
$grey-5 			: rgb(172,172,172);
$grey-6 			: rgb(151,151,151);
$grey-7 			: rgb(125,125,125);
$grey-8 			: rgb(92,92,92);
$grey-9 			: rgb(51,51,51);
$black 				: rgb(0,0,0);

$blue					: rgb(49,51,88);
$bronze				: rgb(144,101,74);
$warmwhite 		: rgb(248,248,248);
$grey					: rgb(196,199,201);
$bg 					: rgb(245,245,245);

$heading 			: Farnham, serif;
$body					: 'Neue Haas Grotesk', sans-serif;

$iosEase 			: 500ms cubic-bezier(0.23,1,0.32,1);
$ease					: 500ms cubic-bezier(0.65, 0, 0.35, 1);


/* --- Scaffolding -- */

$xs-width			: 100%;
$xs-padding 	: 10px;
$xs-menu		  : 60px;

$sm-width			: 576px;
$sm-padding 	: 20px;
$sm-menu		  : 80px;

$md-width			: 768px;

$lg-width			: 992px;

$xl-width			: 1440px;