/* --- Notes --- */



/* --- Start SCSS --- */

#home {
  z-index: 1;
  position: relative;

  .hero {
    position: sticky;
    top: $xs-menu;
    z-index: -1;
    overflow: hidden;
  }

  .wide-container {
    max-width: 1100px;
    margin: 0 auto;
  }

  .intro {
    padding: 25px 0 40px;

    .mask { 
      background-color: hotpink; 
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
      opacity: 0.5;
    }

    h1 { 
      @include font($heading,25,27,600,$bronze);
      margin-bottom: 12px;
    }

    h2 {
      @include font($heading,18,20,400,$blue);
      & + h2 { margin-top: 8px; }
    }

    h3 { 
      @include p($heading,400,$blue);
      margin-top: 15px;
    }
  }

	.blue-bg { background-color: $blue; }
  .white-bg { background-color: $white; }

  .big-links {
    &.can-hover {
      img { 
        opacity: 0;
        transition: opacity $ease;
      }
    }

    img { opacity: 0.8; }

    a {
      @media (hover) {
        &:hover {
          img { opacity: 0.8 }
        }
      }
    }

    a + a { border-top: 1px solid $bronze; }

    ul {
      @include centerXY;
      z-index: 1;
      text-align: center;
      width: 100%;

      li:nth-of-type(1) {
        @include font($heading,35,37,600,$white);
      }

      li:nth-of-type(2) {
        @include p-big;
        color: $white;
        margin-top: 6px;
      }

      li:nth-of-type(3) {
        @include p-small;
        color: $white;
        font-weight: 600;
        margin-top: 8px;
      }
    }
  }

  .team {
    border-top: 1px solid $bronze;
    border-bottom: 5px solid $bronze;

    .team-inner {
      padding-bottom: 30px;
    }

    .team-intro {
      padding: 30px 0 40px;

      h2 { 
        @include font($heading,25,27,600,$bronze);
        margin-bottom: 10px;
      }

      h3 { 
        @include p($heading,400,$blue);
        max-width: 330px;
      }
    }

    figure {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: -10px;
        width: 100%;
        height: 1px;
        background-color: $bronze;
      }

      figcaption {
        @include p-v-small;
        color: $blue;
        display: block;
        padding: 10px 0 30px 0; 
      }
    }


    .accordion-item {
      border-top: 1px solid $bronze;
      padding-top: 2px;
      padding-bottom: 20px;

      &.no-toggle {
        .toggle { display: none; }
      }

      .accordion-title {
        padding: 0;
        cursor: default;

        .toggle { 
          margin-top: 8px;
          cursor: pointer; 

          &:before,
          &:after {
            background-color: $blue;
          }

          @media (hover) {
            &:hover {
              &:before,
              &:after {
                background-color: $bronze;
              }
            }
          }
        }
      }

      .accordion-inner {
        padding: 5px 0 10px 0;
        color: $blue;
      }
    }


    .staff-member {
      color: $blue;

      .name { 
        @include font($heading,16,18,600,$bronze); 
        margin-bottom: 3px;
      }

      .email,
      .phone {
        @include p($body,600,$blue);
        margin-top: 2px;
      }
      
      a { 
        color: $blue;
        
        @media (hover) {
          &:hover {
            color: $bronze;
          }
        }
      }
    }

    .bio { 
      color: $blue; 
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  #home {

    .hero {
      top: $sm-menu;
    }

    .intro {
      padding-top: 45px;
      padding-bottom: 60px;

      h1 { 
        max-width: 400px;
        @include update-font(35,33);
        margin-bottom: 15px;
      }

      h2 {
        max-width: 450px;
        @include update-font(27,25);
        & + h2 { margin-top: 15px; }
      }

      h3 { 
        max-width: 400px;
        @include update-font(17,20);
        margin-top: 25px;
      }
    }

    .big-links {
      a + a { 
        border-top: none;
        border-left: 1px solid $bronze; 
      }
    }

    .team {
      .team-intro {
        padding: 60px 0 40px;

        h2 { 
          @include update-font(35,36);
        }

        h3 { 
          @include update-font(17,20);
          max-width: 520px;
        }
      }

      figure {
        figcaption {
          @include update-font(11,13);
        }
      }

      .staff {
        // grid-auto-rows: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 35px;
        padding: 10px 0 35px;
      }

      .staff-member {
        padding-left: 10px;
        border-left: 1px solid $bronze;
        padding-bottom: 6px;

        .img { 
          padding: 8px 0 12px; 
          max-width: 140px;
        }
        .bio + * { margin-top: 8px; }

      }
    }
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #home {
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  #home {
    
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  #home {
    .intro {
      
    }
  }
}  