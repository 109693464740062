/* --- Notes --- */

// menu height set in _variables
$menu-bg          : $blue;
$nav-item         : $white;
$nav-item-hover   : rgba($white,0.7); 
$nav-item-active  : $bronze;

/* --- Start SCSS --- */

header {
  background-color: $menu-bg;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: $xs-menu;

  .outer { 
    height: 100%;
    z-index: 100; 
  }

  .inner { 
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  .disabled {
    pointer-events: none;
  }

  .overlay-active {
    color: cadetblue;
  }

  // .active:not(.logo) {
  //   color: $nav-item-active;
  // }

  .logo { 
    display: inline-flex;
    width: 200px; 

    svg {
      display: block;
      width: 100%;
    }
  }

  .logo,
  .hamburger { z-index: 200; }

  .hamburger {
    width: 30px;
    cursor: pointer;
    pointer-events: auto;
    position: relative;
    display: block;
    align-self: stretch;

    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      background-color: $nav-item;
      transition: transform $ease, top $ease;
    }

    &:before { top: calc(50% - 4px) }
    &:after { top: calc(50% + 4px) }

    @media (hover) {
      &:hover {
        &:before,
        &:after { background-color: $nav-item-hover }
      }
    }

    &.active {
      &:before,
      &:after {
        top: 50%;
      }

      &:before { transform: rotate(45deg) }
      &:after { transform: rotate(-45deg) }
    }
  }

  .menu {
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 25px;
    @include caps-heading;

    li {
      color: $nav-item;
      cursor: pointer;
      user-select: none;
      padding: 10px 0;

      @media (hover) {
        &:hover {
          color: $nav-item-hover;
        }
      }

      &.active,
      &.overlay-active {
        color: $nav-item-active;
      }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  header {
    height: $sm-menu;
    .logo { width: 250px; }
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  header {

  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  header {
  	
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  header {
  	
  }
}  