/* --- Notes --- */



/* --- Start SCSS --- */


#error {
  .grid {
    max-width: 550px;
    padding-right: 40px;
    margin: 0 auto;
  }

  svg {
    width: 32px;
    margin-top: 30px;
  }

  h1 { 
    @include font($heading,35,35,600,$bronze);
    margin: 30px 0 20px;
  }

  li {
    @include p-big;
    color: $white;

    &:nth-of-type(1) { 
      margin-bottom: 10px;
    }
  }

  a {
    @include p;
    color: $white;
    display: inline-block;
    padding: 5px 0;

    @media (hover) {
      &:hover {
        color: $bronze;
      }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  #error {

  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #error {
   
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  #error {

  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  #error {

  }
}  