/* --- Notes --- */
// honestly, this is a bit of a mess, things were built out nicely, then transitioned
// to position:absolute for some nice fades, naturally this threw everything out of whack
// and everything has been hardcoded and tacked on since. Hardly ideal, but it is what it is.



/* --- Start SCSS --- */




#menu-overlay {
  background-color: $blue;
  padding-bottom: 15px;
  height: 0;
  transition: 500ms ease-in-out;
  overflow: hidden;

  &.active {
    .mask { opacity: 0.8; }
    .menu-overlay-inner { opacity: 1; }
  }

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $blue;
    opacity: 0.8;
    z-index: -1;
    cursor: pointer;
  }

  .mask,
  .menu-overlay-inner {
    opacity: 0;
    transition: 500ms ease-in-out;
  }

  a {
    color: $white;
  }

  .accordion-title {
    @include font($body,25,30,600,$white);
    text-transform: uppercase;

    @media (hover) {
      &:hover {
        .toggle { 
          &:before,
          &:after { background-color: $bronze; }
        }
      }
    }

    .toggle {
      margin: auto 0;
      &:before,
      &:after { background-color: $white; }
    }
  }

  .accordion-inner {
    padding-bottom: 25px;
    @include font($body,14,19,400,$white);

    a {
      padding: 5px 5px 5px 0;
      display: inline-block;

      @media (hover) {
        &:hover {
          opacity: 0.75;
        }
      }
    }

    .capability { text-transform: capitalize; }

    .cs-heading { 
      @include caps-heading($bronze); 
      @include update-font(14,17);
      margin: 15px 0 5px;
    }

    .title li { 
      margin: 0;
      display: inline-block; 

      &:nth-of-type(1) {
        font-weight: 600;
        padding-right: 4px;
      }

      &:nth-of-type(2) {
        white-space: nowrap;
      }
    }
  }

  .contact {
    .contact-details {
      display: grid;
      grid-gap: 5px;
      @include font($body,14,19,400,$white);
      padding-top: 5px;

      ul:nth-of-type(2) { display: none; }

      &.phone-email { margin-top: -5px; }

      a { 
        display: inline-block;
        padding: 5px 0; 
        @media (hover) {
          &:hover {
            color: $bronze;
          }
        }
      }
    }

    .map {
      display: inline-block;
      margin-top: 20px;
      padding: 0;
      width: 250px;
      margin-bottom: -5px;
      transition: all $iosEase;
      border: 1px solid rgb(144, 101, 74);
      overflow: hidden;

      @media (hover) {
        &:hover {
          opacity: 1;
          background-color: rgba($white,0.015);

          .img-wrapper {
            background-color: $blue;
            opacity: 0.75;
          }
        }
      }

      svg {
        width: calc(100% + 2px);
        display: block;
        margin: -1px ;
      }

      .img-wrapper {
        width: 100%;
        height: 211.5px;
        transition: all $iosEase;
      }
    }

    span {
      display: block;
      position: relative;
      z-index: 1;
      background-color: $bronze;
      padding: 10px;
      text-align: center;
      width: 250px;
      user-select: none;
      cursor: pointer;
      @include p-small;
      color: $white;
      font-weight: 600;

      @media (hover) {
        &:hover {
          background-color: darken($bronze,2.5);
        }
      }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  #menu-overlay {

  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {

  #menu-overlay {
    padding: 0;
    max-height: calc(100vh - #{$sm-menu});
    position: relative;

    &.active {
      height: 670px;
      padding-bottom: 60px;
    }

    span {
      position: relative;
      display: block;

      > div { 
        position: absolute;
      }
    }

    .close-wrapper {
      padding: 15px 0 25px;
    }

    .close {
      width: 30px;
      height: 30px;
      cursor: pointer;
      pointer-events: auto;
      position: relative;
      display: block;
      margin-left: auto;
      right: -4px;

      &:before,
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 1px;
        background-color: $nav-item;
        transition: transform $ease, top $ease;
      }

      &:before { 
        top: calc(50% - 0px);
        transform: rotate(45deg);
      }
      &:after { 
        top: calc(50% + 0px);
        transform: rotate(-45deg);
      }

      @media (hover) {
        &:hover {
          &:before,
          &:after { background-color: $bronze; }
        }
      }
    }

    .commercial,
    .residential {
      grid-gap: 25px;

      .intro {
        padding-right: 40px;

        h4 { 
          @include caps-heading($white);
          margin-bottom: 15px; 
        }

        h2 { 
          @include font($heading,35,33,600,$bronze);
          margin-bottom: 15px;
        }

        p {
          @include font($heading,14,17,400,$white);
          & + p { display: none; }
        }

        a {
          cursor: pointer;
          display: inline-block;
          @include font($body,11,13,600,$white);
          padding: 15px 10px 10px 0;

          @media (hover) {
            &:hover {
              color: $bronze;
            }
          }
        }
      }

      .casestudy-grid {
        position: relative;

        &.has-scroll {

          &:before {
            content: "";
            position: absolute;
            width: calc(100% - 11px);
            height: 60px;
            z-index: 1;
            opacity: 0;
            transition: opacity 1000ms;
          }

          &.mask-bottom {
            &:before {
              opacity: 1;
              bottom: -1px;
              @include graident(rgba($blue,0),$blue,180deg);
            }
          }

          &.mask-top {
            &:before {
              opacity: 1;
              top: 41px;
              @include graident(rgba($blue,0),$blue,0deg);
            }
          }

          .projects-wrapper {
            overflow: scroll;
          }
        }

        // use casestudy-grid pseudo as border to handle overflow on small screens
        &:after { 
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 1px;
          height: calc(100% - 43px); // heading
          background-color: $bronze;
        }

        h4 { color: $bronze; }

        .projects-wrapper {
          max-height: 495px; 
          overflow: hidden;

          // hide existing borders
          &:before,
          &:after { display: none; }
        }
      }
    }

    .contact {
      display: grid;
      grid-template-columns: auto auto 1fr;
      padding-top: 0;
      height: 465px;

      .col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:nth-of-type(1) {
          padding-left: 10px;
          border-left: 1px solid $bronze;
          padding-right: 35px;
          justify-content: normal;
        }
        &:nth-of-type(2) {
          padding: 0 25px;
          border-right: 1px solid $bronze;
          border-left: 1px solid $bronze;
        }
      }

      .contact-details {
        grid-gap: 10px;
        margin-bottom: 20px;
        padding-top: 0;
        ul:nth-of-type(2) { display: block; }
      }

      .map {
        margin-top: 0;
        margin-bottom: 0;
      }
      
      .staff {
        display: grid;
        grid-gap: 10px;
        margin-bottom: -2px;

        a { 
          padding: 2px 0; 

          @media (hover) {
            &:hover {
              opacity: 0.75;
            }
          }
        }

        .name { @include font($heading,17,20,600,$bronze); }
        .phone { display: none; }
      }

    }
  } 
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  #menu-overlay {
    
  }
}  


/* --- CUSTOM --- */
@media only screen and (max-width: #{$xl-width - 1}) and (max-height: 750px) { 
  #menu-overlay {
    .commercial,
    .residential {
      .casestudy-grid {
        .projects-wrapper {
          max-height: calc(100vh - 253px);
        }
      }
    }

    .contact {
      max-height: calc(100vh - 210px);
    }
  }
} 


/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  #menu-overlay {

    &.active {
      height: 750px;
    } 

    .commercial,
    .residential {
      grid-gap: 60px;

      .intro {
        padding-right: 60px;

        h2 {
          @include update-font(40,39);
          margin-bottom: 17px;
        }

        p {
          @include update-font(15,18);
          & + p { margin-top: 12px; }
        }
      }

      .casestudy-grid {
        .projects-wrapper {
          max-height: 573px;
        }
      }
    }
  }
}  


// /* --- CUSTOM --- */
@media only screen and (min-width: $xl-width ) and (max-height: 830px) { 
  #menu-overlay {
    .commercial,
    .residential {
      .casestudy-grid {
        .projects-wrapper {
          max-height: calc(100vh - 253px);
        }
      }
    }

    .contact {
      max-height: calc(100vh - 210px);
    }
  }
} 