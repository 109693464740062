
/* --- Notes --- */



/* --- Start SCSS --- */

html {
  box-sizing: border-box;
  background-color: $blue;
}

*, 
*::before, 
*::after {
  box-sizing: inherit;
}

main {
	min-height: 100vh;
	scroll-behavior: smooth;
	max-width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	padding-top: $xs-menu;
	
}

.content{ 
	flex: 1;
	// overflow-x: hidden;
	width: 100%;
	max-width: $xl-width;
	margin: 0 auto;
}

.fluid-container { width: 100%; }

.full-container { 
	width: 100%;
	max-width: $xl-width; 
	margin: 0 auto;
}

.container {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	width: calc(100% - #{$xs-padding * 2});
}

.blue-bg { background-color: $blue; }
.white-bg { background-color: $white; }



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
	main { padding-top: $sm-menu; }
	.container { 
		width: calc(100% - #{$sm-padding * 2});
		max-width: 700px; 
	}
}  


/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
	.container { 
		width: 100%;
		max-width: 700px; 
		padding: 0;
	}
}  


/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 

}  


/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
	.container { max-width: 900px; }
}  