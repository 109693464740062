/* --- Notes --- */



/* --- Start SCSS --- */

.staff {
  .phone,
  .email {
    position: relative;
    padding-left: 15px;

    &:before {
      position: absolute;
      left: 0;
    }
  }

  .phone:before { content: 'p'; }
  .email:before { content: 'e'; }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .staff {

  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .staff {
    
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .staff {

  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .staff {

  }
}  