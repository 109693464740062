/* --- Notes --- */



/* --- Start SCSS --- */

footer {
  padding: 35px 0 35px;
  text-align: center;
  @include font($body,11,13,700,$white);

  &.white {
    background-color: $white;
    color: $blue;

    a {
      color: $blue;
    }
  }
  
  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;

    li {
      margin: 0 10px 10px 10px;
      &:first-child { width: 100%; }
    }
  }

  a {
    color: $white;

    @media (hover) {
      &:hover {
        color: $bronze;
      }
    }
  }
  
  svg {
    width: 32px;
    cursor: pointer;
    margin-bottom: 20px;
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  footer {
    @include update-font(12px,14px);
    padding: 65px 0 45px;
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  footer {

  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  footer {
    ul li:first-child { 
      width: auto; 
      white-space: nowrap;
    }
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  footer {
    &.white { margin-bottom: $sm-menu; }
  }
}  