/* --- Notes --- */



/* --- Start SCSS --- */

#casestudy {
  z-index: 1;
  position: relative;

  .hero {
    position: sticky;
    top: $xs-menu;
    z-index: -1;
  }

  .overview {
    padding-top: 40px;
    padding-bottom: 40px;

    .title {
      h1 { 
        @include caps-heading($blue); 
        margin-bottom: 15px;
      }

      h2 {
        @include font($heading,35,34,600,$bronze);
        margin-bottom: 15px;
      }
    }

    .primary {
      p { 
        @include p($heading,400,$blue);

        & + p {
          margin-top: 12px;
        }
      }

      a {
        @include font($body,11,13,700,$blue);
        display: inline-block;
        margin-top: 15px;
        text-transform: lowercase;

        @media (hover) {
          &:hover {
            color: $bronze;
          }
        }
      }
    }

    .secondary {
      margin-top: 30px;



      ul {
        border-top: 1px solid $bronze;
        padding-top: 2px;

        & + ul {
          margin-top: 20px;
        }

        li:nth-of-type(1) {
          @include caps-heading($bronze);
          margin-bottom: 6px;
        } 

        li:nth-of-type(n+2) {
          @include p-small;
          color: $blue;
          padding-left: 10px;
          position: relative;

          & + li { margin-top: 5px; }

          &:before {
            content: '›';
            position: absolute;
            left: 0;
            top: 0;
          }
        }

        a {
          color: $blue;

          @media (hover) {
            &:hover {
              color: $bronze;
            }
          }
        }
      }
    }
  }

  .media-wrapper {
    padding-bottom: 50px;
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  #casestudy {

    .hero {
      top: $sm-menu;
    }

    .overview {
      grid-template-columns: 60% auto;
      grid-column-gap: 50px;
      grid-row-gap: 20px;
      padding-top: 60px;
      padding-bottom: 80px;

      .title {
        width: 50%;

        h2 {
          margin-bottom: 0;
        }
      }

      .secondary {
        margin-top: 5px;
      }
    }

    .media-wrapper {
      padding-bottom: 80px;
    }
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #casestudy {
    .media-wrapper {
      max-width: 940px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  #casestudy {
    
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  #casestudy {
    .overview {
      padding-top: 60px;
      grid-column-gap: 100px;

      .title {
        h1 { 
          @include update-font(12,14);
        }

        h2 {
          @include update-font(40,39);
        }
      }

      .primary {
        p { 
          @include update-font(15,19);
          & + p { margin-top: 14px; }
        }

        a {
          @include update-font(12,14);
        }
      }

      .secondary {
        ul {
          padding-top: 2px;

          li:nth-of-type(1) {
            margin-bottom: 7px;
          } 

          li:nth-of-type(n+2) {
            @include update-font(12,13);
            & + li { margin-top: 7px; }
          }
        }
      }
    }

    .media-wrapper {
      max-width: 1140px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 100px;
    }
  }
}  