
/* --- Notes --- */


// Vue transitions 

.fade-v-long-enter-active, .fade-v-long-leave-active {
  transition: opacity 2500ms;
}

.fade-long-enter-active, .fade-long-leave-active {
  transition: opacity 1000ms;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 500ms;
}

.fade-v-long-enter-from, 
.fade-v-long-enter,
.fade-v-long-leave-to,
.fade-long-enter-from, 
.fade-long-enter,
.fade-long-leave-to,
.fade-enter-from, 
.fade-enter,
.fade-leave-to,  {
  opacity: 0;
}

.fade-v-long-enter-to,
.fade-v-long-leave-from, 
.fade-v-long-leave
.fade-long-enter-to,
.fade-long-leave-from, 
.fade-long-leave
.fade-enter-to,
.fade-leave-from, 
.fade-leave  {
  opacity: 1;
}



/* --- Start SCSS --- */





/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
	
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
	
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
	
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
	
}  