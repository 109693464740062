
/* --- Notes --- */

// To prevent endless nesting typography via @extend our base typography
// has been moved to @includes. see: /shared/_typography_mixins. 


/* --- Typography --- */
body {
  font-feature-settings: "lnum";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include p($body,400,$black)
}

a { text-decoration: none; }

p.big,
.p-big { @include p-big; }

p.small,
.p-small { @include p-small; }

p.v-small,
.p-v-small { @include p-v-small; }

.strong,
strong { font-weight: 600 !important; }

.white  { color: $white !important; }
.blue   { color: $blue !important; }
.bronze { color: $bronze !important; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup { top: -0.5em; }
sub { bottom: -0.25em; }



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {

}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {

}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 

}  


/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 

}  
