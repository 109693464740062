/* --- Notes --- */



/* --- Start SCSS --- */


.project-teaser {
  @include font($body,11,13,400,$white);
  user-select: none;

  li + li { margin-top: 5px; }

  li.img-wrapper { 
    background-color: $blue;
    overflow: hidden;

    img { transition: all $iosEase; }
  }


  .title {
    li { 
      margin: 0;
      display: inline-block; 

      &:nth-of-type(1) {
        font-weight: 600;
        padding-right: 4px;
      }

      &:nth-of-type(2) {
        white-space: nowrap;
      }
    }
  }


  // blue bg hover
  &.blue {
    .title li { color: $white; }

    @media (hover) {
      &:hover {
        .title li { 
          color: $white; 
          opacity: 0.75;
        }
      }
    }
  }

  // default hover
  @media (hover) {
    &:hover {
      img { 
        opacity: 0.75;
        // transform: scale(1.05);
      }

      .title li { color: $blue; }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .project-teaser {
  	
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .project-teaser {
  	
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .project-teaser {
  	
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .project-teaser {
  	
  }
}  